import './animated-video.scss';
// for all page
import '../../components/app/header/header';
import '../../components/app/footer/footer';
import '../../components/app/menu/menu';
import '../../components/sections/member-section/member-section';
import '../../components/app/mobile-menu/mobile-menu';

import '../../components/sections/hero-animated-video-page/hero-animated-video-page';
import '../../components/sections/more-content/more-content';
