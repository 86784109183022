import './hero-animated-video-page.scss';
import '../hero-quote-page/hero-quote-page.scss';
import VideoFullscreen from '../../../modules/video-fullscreen/video-fullscreen';

const videoFullscreen = new VideoFullscreen({
	videoButtonSelector: '.animated-video__image-wrapper',
	videoModal: '.video-modal',
	videoFile: '.video-modal__file',
});

videoFullscreen.init();
