class VideoFullscreen {
	constructor(settings) {
		this.openButton = document.querySelector(settings.videoButtonSelector);
		this.modal = document.querySelector(settings.videoModal);
		this.video = document.querySelector(settings.videoFile);
	}

	init = () => {
		if (this.openButton && this.modal) {
			this.openButton.addEventListener('click', this.openModal);
		}
	};

	openModal = () => {
		this.modal.requestFullscreen()
			.then(() => {
				this.video.play();
				this.modal.classList.add('video-modal--opened');
				document.addEventListener('fullscreenchange', this.closeModal);
				document.addEventListener('keyup', this.playPauseByKeyBoard);
			});
	};

	closeModal = (event) => {
		if (!document.fullscreenElement || event.keyCode === 27) {
			this.modal.classList.remove('video-modal--opened');
			document.removeEventListener('fullscreenchange', this.closeModal);
			this.video.pause();
			this.video.currentTime = 0;
		}
	};

	playPause = () => {
		if (this.video.paused) {
			this.video.play();
		} else {
			this.video.pause();
		}
	};

	playPauseByKeyBoard = (event) => {
		if (event.keyCode === 32) {
			this.playPause();
		}
	};
}

export default VideoFullscreen;
